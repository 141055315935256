import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../redux/slices'

import { CreditTypeEnum } from '../../__generated__/globalTypes'

export type CreditAccount = {
  id: string
  balance: number
  creditType: CreditTypeEnum
}

type State = {
  selectedCreditAccount: CreditAccount | null
  withdrawModal: boolean
  
}

export const initialState: State = {
  selectedCreditAccount: null,
  withdrawModal: false
}

export type InvoiceModalType =
  | 'viewInvoiceModal'
  | 'regenerateInvoiceModal'
  | 'confirmInvoiceModal'
  | 'deleteInvoiceModal'
  | 'sendInvoiceModal'

const creditSlice = createSlice({
  name: 'credit',
  initialState,
  reducers: {
    displayWithdrawModal(state, action: PayloadAction<{ show: boolean }>) {
      state.withdrawModal = action.payload.show
    },
    setSelectedCreditAccount(
      state,
      action: PayloadAction<CreditAccount | null>
    ) {
      state.selectedCreditAccount = action.payload
    },
    resetSelectedCreditAccount(state) {
      state.selectedCreditAccount = null
    }
  }
})

export const withdrawModalSelector = (state: RootState): boolean =>
  state.creditReducer.withdrawModal

export const creditAccountSelector = (state: RootState): CreditAccount | null =>
  state.creditReducer.selectedCreditAccount

export const { name, actions, reducer } = creditSlice
